import React from 'react';
import { Col, Row } from 'reactstrap';
import TournamentProgrammeWidgetModel from '../models/football-tournament-programme.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import { generateHeaderDefaultOptionViewText } from '../../../partials/header-default-option-input/helper';

type Properties = {
	preview: TournamentProgrammeWidgetModel;
	t: any;
};

export const FootballTournamentProgrammeView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<>
				<Row className='px-4' data-qa='football-tournament-programme-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								{preview.tournament && preview.tournament.name && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournament.name}
									</div>
								)}
								<div className='single-row'>
									<strong>{t('season')}:</strong> {preview.season.name}
								</div>
								{preview.stage && preview.stage.id && (
									<div className='single-row'>
										<strong>{t('stage')}:</strong> {preview.stage.name}
									</div>
								)}
								{preview.rounds && preview.rounds.length > 0 && (
									<div className='single-row'>
										<strong>{t('rounds')}:</strong>
										{preview.rounds.map((round: RoundModel, index) => {
											return (
												<span key={`${round.key}-${index}`} className='pl-1'>
													{round.name}
													{index + 1 === preview.rounds.length ? '' : ','}
												</span>
											);
										})}
									</div>
								)}
							</Col>
						</Row>
						{preview.sortDirectionFixtures ? (
							<Row className='mb-2'>
								<Col>
									<div className='single-row'>
										<strong>{t('sort_direction_fixtures')}:</strong> {t(preview.sortDirectionFixtures)}
									</div>
								</Col>
							</Row>
						) : null}
						{preview.sortDirectionResults ? (
							<Row className='mb-2'>
								<Col>
									<div className='single-row'>
										<strong>{t('sort_direction_results')}:</strong> {t(preview.sortDirectionResults)}
									</div>
								</Col>
							</Row>
						) : null}
						<hr />
						<Row className='mb-2'>
							<Col className='text-center'>
								<Row>
									<Col className='text-left'>
										{t('display_odds')}:
										<strong>
											<span className={preview.displayOdds ? 'text-success' : 'text-danger'}>
												{preview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
											</span>
										</strong>
									</Col>
								</Row>
							</Col>
						</Row>
						{preview.displayOdds && preview.bookmakers && preview.bookmakers.length > 0 && (
							<Row className='mb-2'>
								<Col>
									{t('show')}:
									{preview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
										return (
											<img
												key={bookmaker.id}
												height='20'
												alt={bookmaker.name}
												className='h-20 mx-1'
												src={bookmaker.assets && bookmaker.assets[0] && bookmaker.assets[0].logo ? bookmaker.assets[0].logo : imagePlaceHolder}
												title={bookmaker.name}
											/>
										);
									})}
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{generateHeaderDefaultOptionViewText(preview.headerDefaultOption)}
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
