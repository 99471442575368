import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { FootballTeamSquadWidgetJson } from './football-team-squad.json';
import FootballTeamSquadWidgetBuilder from './football-team-squad.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballTeamSquadWidgetModel {
	readonly team: TeamModel;
	readonly tournamentSeason: EntitySeasonsSelectModel;
	readonly headerDefaultOption: any;
	readonly elements: string[];
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournamentSeason: EntitySeasonsSelectModel,
		team: TeamModel,
		headerDefaultOption: any,
		elements: string[],
		refreshTime: RefreshTimeValuesType,
	) {
		this.team = team;
		this.tournamentSeason = tournamentSeason;
		this.headerDefaultOption = headerDefaultOption;
		this.elements = elements;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballTeamSquadWidgetJson {
		return {
			team: this.team,
			tournamentSeason: this.tournamentSeason,
			headerDefaultOption: this.headerDefaultOption,
			elements: this.elements,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballTeamSquadWidgetJson): FootballTeamSquadWidgetModel {
		return new FootballTeamSquadWidgetModel(json.tournamentSeason, json.team, json.headerDefaultOption, json.elements, json.refreshTime);
	}

	static builder(model?: FootballTeamSquadWidgetModel): FootballTeamSquadWidgetBuilder {
		return new FootballTeamSquadWidgetBuilder(model);
	}
}
