import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballMatchesH2hJson } from './football-matches-h2h.json';
import FootballMatchesH2hBuilder from './football-matches-h2h.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballMatchesH2hModel {
	readonly team1: TeamModel;
	readonly team2: TeamModel;
	readonly limit: string;
	readonly headerDefaultOption: string | null;
	readonly refreshTime: RefreshTimeValuesType = null;

	private constructor(
		team1: TeamModel,
		team2: TeamModel,
		limit: string,
		headerDefaultOption: string | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.team1 = team1;
		this.team2 = team2;
		this.limit = limit;
		this.headerDefaultOption = headerDefaultOption;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballMatchesH2hJson {
		return {
			team1: this.team1,
			team2: this.team2,
			limit: this.limit,
			headerDefaultOption: this.headerDefaultOption,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballMatchesH2hJson): FootballMatchesH2hModel {
		return new FootballMatchesH2hModel(json.team1, json.team2, json.limit, json.headerDefaultOption, json.refreshTime);
	}

	static builder(model?: FootballMatchesH2hModel): FootballMatchesH2hBuilder {
		return new FootballMatchesH2hBuilder(model);
	}
}
