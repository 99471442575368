import React from 'react';
import { Col, Row } from 'reactstrap';
import LivescoreWidgetModel from '../../../blocks/widgets/livescore/models/livescore-widget.model';
import BlockModel from '../../../models/block.model';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import { featuresService } from '../../../../../../App';
import SportOddsBookmakerModel from '../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import SportBookmakerSelect from '../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import { SportTypes } from '../../../../../../constants/sport.types';
import ErrorHandler from '../../partials/error-handler/error-handler';
import { SportsDateSelect } from '../../../blocks/widgets/livescore/components/subcomponents/sports-date.select';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { SPORT_SELECT_ATTRIBUTES } from '../helpers/component-attributes.constants';
import {
	checkIfOddsAutoCheckEnabled,
	createBlock,
} from '../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../constants/block.types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../partials/refresh-time-input/refresh-time-wrapper';
import { LIVESCORE_HEADER_DEFAULT_OPTIONS, onSelectionChangeFromMainComponent } from '../../partials/header-default-option-input/helper';
import HeaderDefaultOptionWrapper from '../../partials/header-default-option-input/header-default-option-wrapper';
import { DisplayOddsCheckbox } from '../odds/components/display-odds-checkbox.component';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: LivescoreWidgetModel;
	isValid: boolean;
};

export default class TennisLivescoreWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetTennisLivescore,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const livescoreModel = LivescoreWidgetModel.builder(this.state.widgetModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.widgetModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: livescoreModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: LivescoreWidgetModel) => this.setState({ ...this.state, widgetModel: model });

	onDateChange = (date: string) => {
		const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();

		this.updateWidgetModelState(
			LivescoreWidgetModel.builder(this.state.widgetModel).withDate(date).withDisplayOdds(isAutoCheckEnabled).build(),
		);
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(LivescoreWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(LivescoreWidgetModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, LivescoreWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	onHeaderDefaultOptionChange = (selectedHeaderDefaultOption: SelectMenuOptionType) => {
		onSelectionChangeFromMainComponent(
			selectedHeaderDefaultOption,
			LivescoreWidgetModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<>
				<div>
					<Row>
						<Col>
							<SportsDateSelect
								t={t}
								onDateChange={this.onDateChange}
								date={widgetModel.date}
								id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.DATE_SELECT}`}
							/>
						</Col>
					</Row>
					{
						<div className='mb-3'>
							<ErrorHandler
								isValueSelected={!widgetModel.date || widgetModel.date === ''}
								arePropsValid={isValid}
								t={t}
								errorMessage='no_date_selected'
							/>
						</div>
					}
					<DisplayOddsCheckbox
						blockId={this.props.block.id}
						onDisplayOddsChange={this.onDisplayOddsChange}
						isChecked={widgetModel.displayOdds}
						sport={SportTypes.TENNIS}
					/>
					{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && (
						<Row className='mb-2'>
							<Col>
								<SportBookmakerSelect
									isValid={this.state.isValid}
									showAllBookmakers={false}
									bookmakerSelectionEnabled={bookmakerSelectionEnabled}
									t={this.props.t}
									valueList={widgetModel.bookmakers ? widgetModel.bookmakers : []}
									onSelect={this.onBookmakerChange}
									contentLanguage={this.props.contentLanguage}
									sportType={SportTypes.TENNIS}
									id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
								/>
							</Col>
						</Row>
					)}
					<HeaderDefaultOptionWrapper
						options={LIVESCORE_HEADER_DEFAULT_OPTIONS}
						value={this.state.widgetModel.headerDefaultOption}
						onOptionChange={this.onHeaderDefaultOptionChange}
					/>
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
