import { BasketballTournamentProgrammeWidgetJson } from './basketball-tournament-programme.json';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import BasketballTournamentProgrammeWidgetModel from './basketball-tournament-programme.model';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class BasketballTournamentProgrammeWidgetBuilder {
	readonly json: BasketballTournamentProgrammeWidgetJson;

	constructor(model?: BasketballTournamentProgrammeWidgetModel | BasketballTournamentProgrammeWidgetJson) {
		if (model && model instanceof BasketballTournamentProgrammeWidgetModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as BasketballTournamentProgrammeWidgetJson;
		}
	}

	withComeptition = (competition: CompetitionModel): BasketballTournamentProgrammeWidgetBuilder => {
		this.json.competition = competition;

		return this;
	};

	withSeason = (season: BasketballSeasonModel): BasketballTournamentProgrammeWidgetBuilder => {
		this.json.season = season;

		return this;
	};

	withStage = (stage: BasketballStageModel): BasketballTournamentProgrammeWidgetBuilder => {
		this.json.stage = stage;

		return this;
	};

	withHeaderDefaultOption(headerDefaultOption: string | null): BasketballTournamentProgrammeWidgetBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withRounds = (rounds: BasketballRoundModel[]): BasketballTournamentProgrammeWidgetBuilder => {
		this.json.rounds = rounds;

		return this;
	};

	withSortDirectionResults = (sortDirectionResults: string): BasketballTournamentProgrammeWidgetBuilder => {
		this.json.sortDirectionResults = sortDirectionResults;

		return this;
	};

	withSortDirectionFixtures = (sortDirectionFixtures: string): BasketballTournamentProgrammeWidgetBuilder => {
		this.json.sortDirectionFixtures = sortDirectionFixtures;

		return this;
	};

	withDisplayOdds(displayOdds: boolean): BasketballTournamentProgrammeWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): BasketballTournamentProgrammeWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): BasketballTournamentProgrammeWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): BasketballTournamentProgrammeWidgetModel {
		return BasketballTournamentProgrammeWidgetModel.fromJson(this.json);
	}
}
