import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import StandingsWidgetV2Model from '../models/standings-widget-v2.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import GroupModel from '../../../../../../../models/v2/Group/entity/group.model';
import TournamentSelectComponent from '../../../partials/tournament-select/tournament-select.component';
import SeasonSelectComponent from '../../../partials/season-select/season-select.component';
import StageSelectComponent from '../../../partials/stage-select/stage-select.component';
import StageGroupSelectComponent from '../../../partials/stage-group-select/stage-group-select.component';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import OffsetInput from '../../../../partials/offset-input/offset-input.component';
import LimitInput from '../../../../partials/limit-input/limit-input.component';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: StandingsWidgetV2Model;
	isValid: boolean;
};

export default class FootballStandingsEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballStandings,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		const standingsModel = StandingsWidgetV2Model.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: standingsModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: StandingsWidgetV2Model) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			StandingsWidgetV2Model.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withSeason({} as SeasonModel)
				.withStage({} as StageModel)
				.withGroup([])
				.withHighlightTeams([])
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(
			StandingsWidgetV2Model.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as StageModel)
				.withGroup([])
				.withHighlightTeams([])
				.build(),
		);
	};

	onStageSelect = (stage: any) => {
		this.updateWidgetModelState(
			StandingsWidgetV2Model.builder(this.state.widgetModel).withStage(stage).withGroup([]).withHighlightTeams([]).build(),
		);
	};

	onStageGroupSelect = (groups: GroupModel[]) => {
		this.updateWidgetModelState(StandingsWidgetV2Model.builder(this.state.widgetModel).withGroup(groups).withHighlightTeams([]).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, StandingsWidgetV2Model, this.state.widgetModel, this.updateWidgetModelState);
	};

	onHighlightTeamsOptions = (teams: TeamModel[]) => {
		this.updateWidgetModelState(StandingsWidgetV2Model.builder(this.state.widgetModel).withHighlightTeams(teams).build());
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(StandingsWidgetV2Model.builder(this.state.widgetModel).withLimit(limit).build());
	};

	onOffsetChange = (offset: string) => {
		this.updateWidgetModelState(StandingsWidgetV2Model.builder(this.state.widgetModel).withOffset(offset).build());
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const hasStage =
			widgetModel.tournament &&
			widgetModel.tournament.id &&
			widgetModel.season &&
			widgetModel.season.id &&
			widgetModel.stage &&
			widgetModel.stage.id;

		return (
			<>
				<div data-qa='standings-edit-component'>
					<Row>
						<Col>
							<FormGroup>
								<TournamentSelectComponent
									isMulti={false}
									isBlocky
									isRequired
									isValid={isValid}
									selectedTournament={widgetModel.tournament}
									onTournamentSelect={this.onTournamentSelect}
									t={this.props.t}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<SeasonSelectComponent
									withLabel
									isValid={isValid}
									isBlocky
									isRequired
									selectedSeason={widgetModel.season}
									tournamentId={widgetModel.tournament && widgetModel.tournament.id ? widgetModel.tournament.id : ''}
									onSeasonSelect={this.onSeasonSelect}
									t={this.props.t}
									preselectSeason
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<StageSelectComponent
									withLabel
									isValid={isValid}
									selectedStage={widgetModel.stage}
									seasonId={widgetModel.season && widgetModel.season.id && widgetModel.season.id}
									onStageSelect={this.onStageSelect}
									t={this.props.t}
									isClearable
									preselectStage
									isRequired={false}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<StageGroupSelectComponent
									withLabel
									isMulti
									t={this.props.t}
									selectedStageGroups={hasStage && widgetModel.group ? widgetModel.group : []}
									stageId={hasStage ? widgetModel.stage.id : ''}
									onStageGroupSelect={this.onStageGroupSelect}
									isClearable
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.GROUP_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.highlightTeams}
									stageId={widgetModel.stage ? widgetModel.stage.id : null}
									seasonId={widgetModel.season ? widgetModel.season.id : null}
									onTeamSelect={this.onHighlightTeamsOptions}
									t={this.props.t}
									isClearable
									searchByKeyword={false}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
									highlightedTeams={true}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<LimitInput onLimitChange={this.onLimitChange} value={widgetModel.limit} />
							</FormGroup>
						</Col>
						<Col>
							<OffsetInput onOffsetChange={this.onOffsetChange} value={widgetModel.offset} type={WidgetsV2Ids.widgetFootballStandings} />
						</Col>
					</Row>
					{
						<div className='mb-3'>
							<ErrorHandler
								arePropsValid={isValid}
								isValueSelected={widgetModel.tournament && !widgetModel.tournament.id}
								t={this.props.t}
								errorMessage='no_tournament_selected'
							/>
						</div>
					}
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
