import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballMatchesH2hJson } from './football-matches-h2h.json';
import FootballMatchesH2hModel from './football-matches-h2h.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballMatchesH2hBuilder {
	readonly json: FootballMatchesH2hJson;

	constructor(matchesH2H?: FootballMatchesH2hModel | FootballMatchesH2hJson) {
		if (matchesH2H && matchesH2H instanceof FootballMatchesH2hModel) {
			this.json = matchesH2H.toJson();
		} else if (matchesH2H) {
			this.json = matchesH2H;
		} else {
			this.json = {} as FootballMatchesH2hJson;
		}
	}

	withTeamOne(team1: TeamModel): FootballMatchesH2hBuilder {
		this.json.team1 = team1;

		return this;
	}

	withTeamTwo(team2: TeamModel): FootballMatchesH2hBuilder {
		this.json.team2 = team2;

		return this;
	}

	withLimit(limit: string): FootballMatchesH2hBuilder {
		this.json.limit = limit;

		return this;
	}

	withHeaderDefaultOption(headerDefaultOption: string | null): FootballMatchesH2hBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballMatchesH2hBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballMatchesH2hModel {
		return FootballMatchesH2hModel.fromJson(this.json);
	}
}
